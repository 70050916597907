import React, { useEffect, useState } from 'react';
import { useSignIn, useIsAuthenticated } from 'react-auth-kit';
import { Navigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { api } from './utils/api';

function TokenLogin() {
  const location = useLocation();
  const [loggingIn, setLoggingIn] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  const signIn = useSignIn();
  const { token } = qs.parse(location.search);

  useEffect(() => {
    if (token && !isAuthenticated()) {
      (async () => {
        try {
          const profile = await api(
            '/me',
            'get',
            {},
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (profile) {
            signIn({
              token,
              expiresIn: 60,
              tokenType: 'bearer',
              authState: profile,
            });
          }
          setLoggingIn(false);
        } catch (error) {
          setLoggingIn(false);
        }
      })();
    }
  }, [token, isAuthenticated, signIn]);

  if (isAuthenticated()) {
    return <Navigate to={'/home'} replace />;
  }
  if (loggingIn) {
    return <div></div>;
  }

  return <Navigate to={'/login'} />;
}

export default TokenLogin;
