import React from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import GuestLayout from './GuestLayout';
import SecureLayout from './SecureLayout';
import Home from './pages/Home';
import Transactions from './pages/Transactions';
import Profile from './pages/Profile';
import PointStatement from './pages/PointStatement';
import Vouchers from './pages/Vouchers';
import PrepaidStatement from './pages/PrepaidStatement';
import HoursStatement from './pages/HoursStatement';
import Feedback from './pages/Feedback';
import ChangePassword from './pages/ChangePassword';
import DeleteProfile from './pages/DeleteProfile';
import NotFound from './pages/NotFound';
import Login from './Login';
import TokenLogin from './TokenLogin';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const auth = isAuthenticated();
  return auth ? children : <Navigate to="/login" />;
};

function Routes() {
  return (
    <RouterRoutes>
      <Route element={<GuestLayout />}>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/token_login" element={<TokenLogin />} />
      </Route>
      <Route
        path="/change-password"
        element={
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/delete-profile"
        element={
          <PrivateRoute>
            <DeleteProfile />
          </PrivateRoute>
        }
      ></Route>
      <Route
        element={
          <PrivateRoute>
            <SecureLayout />
          </PrivateRoute>
        }
      >
        <Route path="/home" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/points" element={<PointStatement />} />
        <Route path="/prepaid" element={<PrepaidStatement />} />
        <Route path="/hours" element={<HoursStatement />} />
        <Route path="/vouchers" element={<Vouchers />} />
        <Route path="/feedback" element={<Feedback />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </RouterRoutes>
  );
}

export default Routes;
